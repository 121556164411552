
.welcome {
    background-color: #282C34;
    display: flex;
    align-items: center;
    padding: 2rem;
    margin-top: 2rem;
    margin-left: 10rem;
    margin-right: 15rem;
    font-family: 'Courier New', monospace;
    color: #00FF41;
    font-weight: 700;
}

.text {
    flex: 1;
    margin-left: 2rem;
    font-size: 20px;
    font-weight: 800;
}
  
.image {
    flex: 1;
    max-width: 30%;
    max-height: 25%;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #00FF41;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.5s ease-in-out;
    animation: borderMorph 8s infinite;
}

.image:hover {
    transform: scale(1.1);
}
  
.profile-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
}


@keyframes borderMorph {
    0% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }

    50% {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }
    100% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}


@media screen and (max-width: 768px) {
    .welcome {
        flex-direction: column;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
    }


    .image {
        flex: 1;
        max-width: 40%;
        max-height: auto;
    }

}

@media screen and (max-width: 1180px) {
    .welcome {
        flex-direction: column;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
    }


    .image {
        flex: 1;
        max-width: 40%;
        max-height: auto;
    }

}
  
