.skills {
    background-color: #282C34;
    color: #eaeafc;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 1rem;
    margin-top: 10rem;
    margin-left: 10rem;
    margin-right: 10rem;
    border: 2px solid #00FF41; 
    animation: morph 8s infinite, gradientBG 15s ease infinite;
    box-shadow: 0 0 5px rgba(0, 255, 65, 0.2);
    padding: 8rem;
    justify-content: center; 
    align-items: center;
}

.skills-title {
    grid-column: 1 / -1;
    text-align: center;
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 2rem;
    margin-top: -5rem;
    font-family: 'Courier New', monospace;
    color: #00FF41;
}


.icon-wrapper {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 100px;
    margin: 5px;
    padding:0;
    background: linear-gradient(135deg, rgba(75, 85, 99, 0.9) 65%, rgba(59, 68, 81, 0.2) 90%); 
    backdrop-filter: blur(10px);
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    transition: transform 0.5s ease-in-out;
}

.icon-wrapper:hover {
    transform: scale(1.1) rotate(20deg);
    box-shadow: 0 4px 50px rgba(59, 68, 81, 0.9), 0 1px 3px rgba(75, 85, 99, 0.9); 
}


.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    color: #E5E7EB;
}



.icon-name {
    position: absolute;
    bottom: 1%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 12px;
    font-weight: 600;
}
  
  

@keyframes morph {
    0% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
    50% {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }
    100% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}


@keyframes gradientBG {
    0%, 100% {
        background-image: linear-gradient(120deg, #666e7e, #348949, #707a8e);
    }
    50% {
        background-image: linear-gradient(120deg, #00FF41, #282C34, #00FF41);
    }
}

@media screen and (max-width: 768px) {
    .skills {
        margin-top: 10rem;
        margin-left: 0;
        margin-right: 0;
    }
}

@media screen and (max-width: 600px) {
    .skills {
        margin-top: 10rem;
        margin-left: 0;
        margin-right: 0;
    }
}