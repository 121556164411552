.projectCard {
    cursor: pointer;
    overflow: hidden;
    border: 1px solid #3D4450;
    border-radius: 7px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.90);
    width: 80%;
    background-color: #282C34;
    text-align: center;
    font-family: 'Courier New', monospace;
    color: #00FF41;
    padding: 20px;
    margin-bottom: 10rem;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.projectCard:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.90);
}

.pname {
    font-size: 30px;
    font-weight: 900;
    margin-top: 0;

}


.projectContent {
    font-weight: 700;
}

.pskills {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -42px;
}

.pskills li::before {
    content: "•";
    margin-left: 2px;
    margin-right: 2px;
}

.pskills li {
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
    padding: 5px;
    border-radius: 3px;
}

.pskills li:hover {
    transform: translateY(-5px);
    background-color: #3D4450
}
